<script setup lang="ts">
import type { NuxtError } from '#app';

const props = defineProps({
  error: Object as () => NuxtError,
});

const handleError = () => clearError({ redirect: '/' });
</script>

<template>
  <div
    class="flex flex-col items-center justify-center h-screen text-center bg-gray-200 mb-l"
    style="width: 100%"
  >
    <h1 class="text-6xl font-bold text-red-500">{{ error.statusCode }}</h1>
    <h2 class="text-4xl">{{ error.message }}</h2>
    <p class="text-lg text-gray-700">
      Oops! Something went wrong. Please try again.
    </p>
    <p class="text-lg text-gray-700">
      Old link not working? We migrated our app to V2, you can still access old
      spells at <a href="https://v1.respell.ai">v1.respell.ai</a>
    </p>
    <button
      class="mt-10 px-6 py-2 text-lg font-bold text-white bg-primary-500 hover:bg-primary-700"
      @click="handleError"
    >
      Return Home
    </button>
  </div>
</template>
